/**
 * Swedish
 */

export default {
  LOCALE: {
    ISO: {
      "639-1"  : "sv",
      "3166-1" : "SE",
      "IETF"   : "sv-SE",
    },
    CURRENCY          : "SEK",
    FALLBACK_CURRENCY : " kr",
    TYPEKIT_ID        : "thm8nca",
    GTM_ID            : "GTM-TCMFK68",
  },
  META: {
    TITLE             : "Julklappsvalet.se – ge dina medarbetare något de faktiskt vill ha",
    NAME              : "Julklappsvalet",
    DESCRIPTION       : "Vi gör det enkelt att belöna dina medarbetare med en julgåva eller företagspresent. Vi erbjuder flera värdekategorier och varje kategori innehåller något för alla, så att dina medarbetare kan välja något de faktiskt vill ha!",
    GOOGLE_SITE_OWNER : "ptV7J-hCFbkxb37lNA_fVl8i6v3Hs98YHCe6Fzoocwc",
  },
  ROUTE: {
    TERMS    : "/terms",
    PRIVACY  : "/privacy-policy",
  },
  EXTERNAL: {
    ACQUIRE_URL : "https://julklappsvalet.se",
  },
  LOGO: {
    ALT   : "Julklappsvalet",
    TITLE : "Gå till startsidan",
    HREF  : "/"
  },
  LOGOUT: {
    HREF        : "/logout",
    TEXT        : "Logga ut",
    TITLE       : "Loggar ut…",
    HEADING     : "Loggar ut…",
    INFORMATION : "Vänligen försök ladda om sidan om du inte loggats ut inom några sekunder."
  },
  OCM: {
    HOME: "Till startsidan"
  },
  OLD_BROWSER: {
    INFO: "Hej! Du använder en utdaterad webbläsare. Vänligen <a href=\"http://outdatedbrowser.com\" rel=\"noopener\" target=\"blank\">uppdatera din webbläsare</a> för att använda Julklappsvalet.se"
  },
  GIFT_CATEGORY: {
    TITLE      : "Välj din gåva",
    HEADING    : "Vad önskar du dig?",
    SUBHEADING : "Mjukt eller hårt paket?",
    DELIVERY   : "Beställ senast 16/12 för leverans innan jul!",
    TEMPORARY  : "Tillfälligt underhåll. Vänligen återkom senare!",
    ALL_CATEGORIES: 'Alla produkter',
    SPECIAL    : {
      HEADING    : "God jul",
      SUBHEADING : "Önskar %companyName",
    },
    DEMO       : {
      HEADING    : "Demo",
      SUBHEADING : "%companyName",
    },
    GREETING   : {
      HEADING    : "God jul, %name!",
      SUBHEADING : "Vad önskar du dig?",
    },
  },
  CATEGORY_SELECTION: {
    DROPDOWN_LABEL: 'Kategorier'
  },
  DEMO_CATEGORY: {
    TITLE : "DEMO | Välj din gåva"
  },
  404: {
    TITLE      : "Sidan kunde inte hittas",
    HEADING    : "— 404 —",
    SUBHEADING : "Sidan kunde inte hittas",
  },
  SERVER_ERROR: {
    TITLE      : "Serverfel",
    HEADING    : "Serverfel: 500",
    SUBHEADING : "Vänligen återkom inom kort!",
    TEXT       : "<strong>Behöver du kontakta oss?</strong><br/> Skicka ett mail till <a href=\"mailto:hej@julklappsvalet.se\">hej@julklappsvalet.se</a>"
  },
  MENU: {
    OPEN  : "Öppna meny",
    CLOSE : "Stäng meny",
  },
  LOGIN: {
    TITLE       : "Logga in",
    HEADING     : "Välkommen!",
    SUBHEADING  : "Välj din julklapp",
    TEXT        : "Här loggar du in med hjälp av den gåvokortskod du fått. I nästa steg väljer du vilken julklapp du vill ha i ett stort urval från välkända varumärken. När du valt julklapp skickar vi den till ditt närmaste utlämningsställe.",
    SUBMIT      : "Välj julklapp",
    SUBMITTING  : "Loggar in…",
    LABEL       : "Gåvokortskod",
    PLACEHOLDER : "Gåvokortskod",
    BACKGROUND  : "/assets/images/jvalet_login_1920x1080.jpg",
    BACKGROUND_POSITION: "center",
    LINK        : {
      HREF : "/login",
      TEXT : "Välj julklapp",
    }
  },
  CMS: {
    BACK: "Tillbaka till gåvor",
  },
  FOOTER: {
    COPYRIGHT                 : "Julklappsvalet.se drivs av Awardit CLS AB (publ)<br/>Copyright © %currentYear <a href=\"https://www.awardit.com\" target=\"_blank\" rel=\"noopener noreferrer\">Awardit CLS</a>",
    PRIVACY_POLICY_LINK_TEXT  : "Integritetspolicy",
    TERMS_LINK_TEXT           : "Villkor",
  },
  PRODUCT: {
    ADD_TO_CART   : "Välj gåva",
    GO_BACK       : "Gå tillbaka till gåvolistan",
    ADDING        : "Väljer…",
    ADDED         : "Tillagd!",
    OUT_OF_STOCK  : "Ej i lager.",
    LOW_STOCK     : "Begränsat antal",
    REPLACEMENT   : "Liknande produkt kommer inom kort",
    READ_MORE     : "Läs mer",
    VIEW_DEMO     : "Se demo",
    PLEASE_CHOOSE : "Välj %attr",
    POPULAR       : "Mest populära julklapparna",
    CODE_EXPIRED  : "Giltighetsdatumet för din gåvokortskod har passerat (%expiredAt).",
    CODE_USED     : "Denna gåvokortskod har redan förbrukats.",
    DEMO          : "Detta är endast en förhandsgranskning. Du måste logga in för att göra ditt val."
  },
  CUSTOM_OPTION: {
    ERROR_MAX: "Maxlängd är %max tecken. Du har %current tecken.",
  },
  DROPDOWN: {
    TOGGLE: "Välj"
  },
  CONTACT: {
    CUSTOMER_SERVICE: "Kundtjänst",
    URL: "/vanliga-fragor",
  },
  DEMO: {
    HEADING : "Detta är en demo",
    INFO    : "Produkterna är exempelprodukter som ger en bild av de produktkategorier som kommer erbjudas. Inga julklappsbeställningar som läggs i demoläge kommer att skickas.",
    CLOSE   : "Stäng meddelandet",
  },
  CHECKOUT: {
    TITLE           : "Leveransinformation",
    CHANGE_GIFT     : "Ångrat dig? Klicka här för att byta gåva",
    REMOVING        : "Tar bort din valda gåva…",
    SUBMIT          : "Bekräfta val",
    HEADING         : "Vart skall vi skicka din gåva?",
    SUBHEADING      : "Ange din leveransadress nedan",
    INFO_HEADING    : "Kontrollera dina uppgifter!",
    INFO_TEXT       : "Säkerställ att ev. adress som förifyllts av din arbetsgivare är korrekt.",
    FILL_ALL_FIELDS : "Du har inte fyllt i samtliga fält",
    DEMO_MODE       : "Inga ordrar kan placeras i demoläget.",
  },
  ADDRESS: {
    FIRSTNAME : {
      LABEL   : "Förnamn",
    },
    LASTNAME  : {
      LABEL   : "Efternamn",
    },
    COMPANY   : {
      LABEL   : "Företag",
    },
    STREET    : {
      LABEL   : "Adress",
    },
    STREET_2  : {
      LABEL   : "Adress 2",
    },
    POSTCODE  : {
      LABEL   : "Postnummer",
      PATTERN : "^\\d{3}\\s?\\d{2}$",
    },
    CITY      : {
      LABEL   : "Stad",
    },
    EMAIL     : {
      LABEL   : "E‑post",
      PATTERN : "^\\S+@\\S+\\.\\S{2,}$",
    },
    TELEPHONE : {
      LABEL   : "Telefon- / Mobilnummer (för sms-avi)",
      PATTERN : "^(?=.*[0-9])[- +()0-9]+$",
    },
  },
  VALIDATION: {
    REQUIRED  : "Detta fält är obligatoriskt",
    EMAIL     : "Vänligen fyll i en korrekt e‑postadress.",
    PHONE     : "Vänligen fyll i ett korrekt mobiltelefonnummer.",
    NUMERIC   : "Fältet får bara innehålla nummer",
    LENGTH_LT : "Fältet innehåller för många tecken",
    POSTCODE  : "Vänligen fyll i ett korrekt postnummer. (XXX XX)"
  },
  SUCCESS: {
    TITLE     : "Tack!",
    HEADING   : "Ditt val av julklapp har registrerats!",
    LABEL     : "Ditt julklappsval",
    ORDER_ID  : "Ordernummer %id",
    QUESTIONS : {
      HEADING : "Har du en fråga om din beställning?",
      LABEL   : "Kontakta kundtjänst",
      URL     : "/vanliga-fragor",
    },
    STEPS : {
      NORMAL : {
        1: {
          HEADING : "Bekräftelse",
          TEXT    : "En beställningsbekräftelse kommer att skickas till %email inom kort.",
        },
        2: {
          HEADING : "Plock- och pack",
          TEXT    : "Vi plockar och packar din beställning i vår julklappsverkstad.",
        },
        3: {
          HEADING : "Transport",
          TEXT    : "När dina varor packats och skickats så meddelas du via e‑post. I leveransbekräftelsen finner du en spårningslänk med vilken du kan följa paketets väg.",
        },
        4: {
          HEADING : "Leverans",
          TEXT    : "Vi levererar din julklapp till ditt närmaste utlämningsställe. Du kommer aviseras via sms till det mobilnummer du har angivit i beställningen. Paketet ligger kvar på utlämningsstället i 14 dagar.",
        },
      },
      VIRTUAL: {
        1: {
          HEADING : "Bekräftelse",
          TEXT    : "En beställningsbekräftelse kommer att skickas till %email inom kort.",
        },
        2: {
          HEADING : "Leverans via email",
          TEXT    : "Din julklapp kommer att skickas till %email. Har du inte fått något email ber vi dig dubbelkolla ditt spam-filter innan du kontaktar kundservice."
        },
      },
      SPECIAL: {
        1: {
          HEADING : "Bekräftelse",
          TEXT    : "En beställningsbekräftelse kommer att skickas till %email inom kort.",
        },
        2: {
          HEADING : "Leverans",
          TEXT    : "Produkten du beställt kommer att levereras av din arbetsgivare i samband med att samtliga medarbetare slutfört sitt val av gåva. Eventuella frågor om leverans hänvisas till din arbetsgivare."
        },
      },
    },
  },
  STATUS_CODE: {
    /* client */
    UNKNOWN:       "Ett okänt fel har uppstått. Vänligen ladda om sidan och försök igen.",
    INVALID_CODE:  "Felaktig gåvokortskod.",
    NETWORK_ERROR: "Anropet avbröts. Vänligen verifiera att du har en aktiv internetanslutning eller försök igen om en stund.",

    /* product */
    1000: "Produkten kunde inte hittas.",

    /* cart */
    2000: "Produkten finns inte i lager.",
    2001: "Önskat antal är inte tillgängligt.",
    2002: "Produkten saknar attributval.",
    2003: "Produkten kunde inte läggas till i kundvagnen.",
    2004: "Vänligen ange produkt och antal.",
    2005: "Vänligen ange produkt.",
    2006: "Produkten kunde inte hittas.",
    2007: "Produkten finns inte längre i kundvagnen.",
    2008: "Produkten kunde inte läggas till i kundvagnen.",
    2009: "Produkten finns inte längre i kundvagnen.",
    2010: "Högsta tillåtna antal för begärd produkt är överskridet.",
    2011: "Något gick fel. Vänligen kontakta kundtjänst.",
    2012: "Produkten kunde inte läggas till i kundvagnen.",
    2013: "Vänligen välj något för alla nödvändiga alternativ.",

    /* checkout */
    4000: "Felaktigt betalsätt.",
    4001: "Felaktigt betalsätt.",
    4002: "Felaktigt leveranssätt.",
    4003: "Felaktigt leveranssätt.",
    4004: "Kundvagnen kunde inte hittas.",
    4005: "Adressuppgifterna innehåller ett fel.",
    4006: "Adressuppgifterna kunde inte verifieras.",
    4007: "Ett okänt fel uppstod.",
    4008: "Din kundvagn är tom.",
    4009: "Leveransadressen kunde inte verifieras.",
    4010: "Vänligen välj leveranssätt.",
    4011: "Faktureringsadressen kunde inte verifieras.",
    4012: "Vänligen välj betalsätt.",
    4013: "Vi har för närvarande problem med den valda betalmetoden. Vänligen försök med en annan.",
    4014: "Betalningsuppgifterna kunde inte verifieras.",
    4015: "Ett okänt fel uppstod.",
    4016: "Det valda betalsättet är inte tillgängligt.",
    4017: "Obligatoriska villkor är inte godkända.",
    4018: "Ett fel har uppstått. Vänligen kontakta kundtjänst.",

    /* project specific */
    101: "Något gick fel. Verifiera att du har en giltig session.",
    102: "Produkten du försökte välja tillhör inte en godkänd gåvokategori.",
    103: "Kvantiteten kan inte överstiga 1 när produkten hämtas ut som gåva.",
    104: "Något gick fel. Verifiera att du har en giltig session.",
    105: "Kvantiteten kan inte överstiga 1 när produkten hämtas ut som gåva.",
    106: "Sessionsfel. Vänligen försök igen.",
    107: "Butiken är för närvarande avstängd.",
    108: "Butiken är för närvarande avstängd.",
    109: "Butiken är för närvarande avstängd.",
    110: "Koden du angav är ogiltig. Vänligen kontrollera att du fyllt i rätt kod.",
    111: "Vänligen ange en kod.",
    112: "Vänligen ange en giltig e-postadress",
    113: "Sessionsfel. Vänligen försök igen senare.",
    114: "Felaktig kod",
    115: "Felaktig kod",
    116: "Giltighetstiden för din inloggningslänk har löpt ut",
    117: "Felaktig kod",
    118: "Butiken är för närvarande avstängd.",
    119: "E-postadress saknas",
    120: "Email kunde inte skickas. Vänligen försök igen senare.",
    121: "Angiven e-postadress är redan registrerad på ett konto. Vänligen logga in för att lägga order.",
    122: "Vi kunde inte skapa en användarprofil, vänligen kontakta kundtjänst med ditt ordernummer.",
    123: "Efterfrågad batch är inaktiverad",
    124: "Koden kunde inte hittas",
    125: "Några av koderna du försökte skicka innehåller felaktigt formaterade e‑postadresser. De koderna har inte skickats.",
    126: "Din kod måste förbrukas i en annan butik. Omdirigerar…",
    127: "Några av koderna du försökte skicka kunde inte skickas. Vänligen försök igen senare.",
  },
};
